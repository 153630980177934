import Vue from "vue"

Vue.filter("formatNumber", value => {
  if (!value) return ""
  value = value.toString()
  value = value.replaceAll(/\./g, "")
  let val = (value / 1).toFixed(0).replace(".", ",")
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
})

Vue.filter("cleanString", valueString => {
  if(!valueString) return ""
  valueString = valueString.toString()
  const regex = /([#0-9]\u20E3)|[\xA9\xAE\u203C\u2047-\u2049\u2122\u2139\u3030\u303D\u3297\u3299][\uFE00-\uFEFF]?|[\u2190-\u21FF][\uFE00-\uFEFF]?|[\u2300-\u23FF][\uFE00-\uFEFF]?|[\u2460-\u24FF][\uFE00-\uFEFF]?|[\u25A0-\u25FF][\uFE00-\uFEFF]?|[\u2600-\u27BF][\uFE00-\uFEFF]?|[\u2900-\u297F][\uFE00-\uFEFF]?|[\u2B00-\u2BF0][\uFE00-\uFEFF]?|(?:\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDEFF])[\uFE00-\uFEFF]?/g
  valueString = valueString.replace(regex, "")
  valueString = valueString.replace("  ", "" )
  return valueString.replace(" ⠀⠀", "\r\n" )
})