export default {
  namespaced: true,
  state: {
    accessories: [],
  },
  mutations: {
    SET_ACCESSORIES(state, payload) {
      payload.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })
      state.accessories = payload
    }
  },
  actions: {
    getAccessories: ({ commit }) => {
      try {
        const accessories = [
          { id:1, name:"Alarma", icon:"alarma.svg" },
          { id:2, name:"Butacas calefacciónadas", icon:"calefaccion_asientos.svg" },
          { id:3, name:"Carplay", icon:"carplay.svg" },
          { id:4, name:"Asientos de cuero", icon:"asientos_de_cuero.svg" },
          { id:5, name:"Asientos electricos", icon:"asientos_electricos.svg" },
          { id:6, name:"Corrector de carril", icon:"ayuda_mantenimiento_carril.svg" },
          { id:7, name:"Bluetooth", icon:"bluetooth.svg" },
          { id:8, name:"Aire Acondicionado", icon:"ac.svg" },
          //{ id:8, name:"Boton emergencia", icon:"boton_emergencia.svg" },
          { id:9, name:"Camara de retroceso", icon:"camara_trasera.svg" },
          { id:10, name:"Alza vidrios eléctricos", icon:"alza_vidrios_electricos.svg" },
          //{ id:10, name:"Cambio automático", icon:"cambio_automatico.svg" },
          { id:11, name:"Cargador inalámbrico", icon:"cargador_inalambrico.svg" },
          { id:12, name:"Climatizador", icon:"climatizador_automatico.svg" },
          { id:13, name:"Control Crucero - Velocidad Crucero", icon:"control_de_crucero.svg" },
          { id:14, name:"Control de tracción", icon:"control_de_estabilidad.svg" },
          { id:15, name:"Velocidad crucero adaptativa", icon:"adaptive_cruise_control.svg" },
          { id:16, name:"Comandos al volante", icon:"controles_en_volante.svg" },
          { id:17, name:"Cierre Centralizado", icon:"cierre_centralizado.svg" },
          { id:18, name:"Focos Led", icon:"faros_led.svg" },
          { id:19, name:"Sensores acercamiento", icon:"parktronic_delantero.svg" },
          { id:20, name:"Isofix", icon:"isofix.svg" },
          { id:21, name:"Smart Key", icon:"smart-key.svg" },
          //{ id:21, name:"Keyless", icon:"keyless.svg" },
          { id:22, name:"Kit antopinchazos - Kit de emergencia", icon:"kit_antipinchasos.svg" },
          { id:23, name:"Limitador de velocidad", icon:"limitador_de_velocidad.svg" },
          { id:24, name:"Llantas de Aleacion", icon:"llantas_de_aleacion.svg" },
          { id:25, name:"Smart key con sensor de proximidad", icon:"smart-key-sensor-proximidad.svg" },
          //{ id:25, name:"Mirror screen", icon:"mirror_screen.svg" },
          { id:26, name:"Espejos con sensor de punto ciego", icon:"detector_obstaculos_en_angulo_muerto.svg" },
          //-{ id:26, name:"Control de tracción", icon:"control_de_traccion.svg" },
          { id:27, name:"Sensores de retroceso", icon:"parktronic_trasero.svg" },
          { id:28, name:"Pintura metalizada", icon:"pintura_metalizada.svg" },
          { id:29, name:"Portalón apertura automática", icon:"porton_trasero_electrico.svg" },
          { id:30, name:"Asistente Frenado Emergencia", icon:"prevencion_de_colision.svg" },
          { id:31, name:"Cámara 360°", icon:"camara-360.svg" },
          { id:32, name:"Espejo Retrovisor Electronico", icon:"retrovisor_electrocromico.svg" },
          { id:33, name:"Retrovisores plegables electricamente", icon:"retrovisores_plegables_electricamente.svg" },
          { id:34, name:"Selector modos de conduccion", icon:"selector_modos.svg" },
          { id:35, name:"Sensor de luces y lluvia", icon:"sensor_luces_y_lluvia.svg" },
          { id:36, name:"Sensor de luz", icon:"sensor_luz.svg" },
          { id:37, name:"Sonido premium", icon:"sonido_premium.svg" },
          { id:38, name:"Suspensión Regulable", icon:"suspension_adaptativa.svg" },
          { id:39, name:"Techo panoramico", icon:"techo_panoramico.svg" },
          { id:40, name:"Sunroof", icon:"techo_solar.svg" },
          { id:41, name:"Turbo", icon:"turbo.svg" },
          { id:42, name:"Usb/Aux", icon:"usb.svg" },
          { id:43, name:"BiTurbo", icon:"biturbo.svg" },
          { id:44, name:"Twin-Turbo", icon:"twin-turbo.svg" },
          { id:45, name:"Partida a distancia", icon:"smart-key-sensor-proximidad.svg" },
          { id:46, name:"Sensores tráfico cruzado", icon:"cross_traffic_alert.svg" },
          { id:47, name:"butacas climatizadas (calor frío)", icon:"asiento_ventilado.svg" },
          { id:48, name:"butacas con memoria", icon:"seats_memory.svg" },
          { id:49, name:"calefacción asientos traseros", icon:"rear_seat_heating.svg" },
          { id:50, name:"Luces xenon", icon:"faros_led.svg" },
        ]
        commit('SET_ACCESSORIES', accessories);
      } catch (error) {
        console.log('Error setAccessories', error)
      }
    }
  },
  getters: {
    getAccessoryById: (state) => (id) => {
      return state.accessories.find(todo => todo.id === id)
    },
  }
}