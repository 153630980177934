import Vue from "vue"
import axios from "axios"
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

export default {
  namespaced: true,
  state: {
    bodyworks: [],
  },
  mutations:{
    SET_BODYWORKS(state, payload){
      state.bodyworks = payload
    },
    SHIFT_BODYWORKS(state) {
      state.bodyworks.shift()
    }
  },
  actions: {
    getBodyworks({ commit }) {
      axios
        .get('/api/v1/bodyworks')
        .then(({ data:{data:bodyworks} })=>{
          commit('SET_BODYWORKS', bodyworks)
        })
        .catch(error => {
          this._vm.$bvToast.toast("Problemas al cargar las carrocerias :(", {
            title: "Error de carga desde API",
            variant: "danger",
            solid: true
          });
          console.error(error)
      })
    },
    shiftBodywork({ commit }) {
      commit('SHIFT_BODYWORKS');
    }
  },
  getters: {
    getCurrentBodywork: (state) => (slug) => {
      return state.bodyworks.find(todo => todo.slug === slug)
    },
    getBodyworkById: (state) => (id) => {
      return state.bodyworks.find(todo => todo.id === id)
    },
  }
}