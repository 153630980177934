<template lang="pug">
#app
	Navbar
	router-view
	Footer
</template>
<script>
import { mapActions } from "vuex";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Footer,
    Navbar
  },
  created() {
    this.getMaxImages()
  },
  methods: {
    ...mapActions("image", ["getMaxImages"])
  },
};
</script>
<style>
#app {
  overflow-x: hidden;
}
</style>
