import Vue from "vue"
import axios from "axios"
import Hashids from "hashids"
import VueAxios from "vue-axios"

Vue.use(VueAxios, axios)

export default {
  namespaced: true,
  state: {
    credentials: {
      id: null,
      name: null,
      active: false,
      role:0,
    },
    token: localStorage.getItem("token") || null,
    isAdmin: false,
    isModerator: false,
    isProfessional: false,
    isUser: true,
  },
  mutations: {
    LOGIN(state, data) {
      state.credentials.id = data.user.id
      state.credentials.role = data.user.role_id
      state.credentials.name = data.user.name
      state.credentials.phone = data.user.phone
      state.credentials.email = data.user.email
      state.credentials.avatar = data.user.avatar
      state.credentials.active = true
      state.token = data.success.token
    },
    SET_TOKEN(state, token) {
      state.token = token
      localStorage.setItem("token", token)
    },
    LOGOUT(state) {
      state.credentials.id = null
      state.credentials.role = null
      state.credentials.name = null
      state.credentials.phone = null
      state.credentials.email = null
      state.credentials.avatar = null
      state.credentials.active = false
      state.token = null;
    },
    IS_ADMIN(state, data) {
      state.isAdmin = data
    },
    IS_MODERATOR(state, data) {
      state.isModerator = data
    },
    IS_PROFESSIONAL(state, data) {
      state.isProfessional = data
    },
    SET_CURRENT_AVATAR(state, data){
      state.credentials.avatar = data
    },
  },
  actions: {
    login({commit}, credentials) {
      const params = {
        email: credentials.email,
        password: credentials.password
      }
      return new Promise((resolve, reject) => {
        axios
          .post("/api/v1/login", params)
          .then(({data}) => {
            const token = data.success.token
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            commit("SET_TOKEN", token)
            commit("LOGIN", data)
            resolve(data)
          })
          .catch(error => {
            this._vm.$bvToast.toast("Email o contraseña invalidos", {
              title: "Inicio de sesión",
              toaster: "b-toaster-top-center",
              variant: "danger",
              solid: true
            })
            reject(error)
          })
      })
    },
    checkIsRole({ commit }) {
      const hashids = new Hashids(process.env.VUE_APP_HASHIDS_SALT, 255, process.env.VUE_APP_HASHIDS_ALPHABET)
      const role = hashids.decode(this.state.auth.credentials.role)[0]
      commit("IS_ADMIN", ( role === 3 ))
      commit("IS_MODERATOR", ( role === 4 ))
      commit("IS_PROFESSIONAL", ( role === 2 ))
    },
    register({commit}, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("api/v1/register", formData)
          .then(({data}) => {
            const token = data.success.token
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            commit("SET_TOKEN", token)
            commit("LOGIN", data)
            resolve(data)
          })
          .catch(error => {
            let errors = error.response.data
            const errorsArray = []
            Object.keys(errors).forEach(function(err) {
              errorsArray.push(errors[err])
            });
            reject({ errors: errorsArray.flat() })
          })
      })
    },
    logout({ commit, state, getters }) {
      axios.defaults.headers.common.Authorization = `Bearer ${state.token}`;
      if (getters.getCredentialLogin) {
        return new Promise((resolve, reject) => {
          commit("LOGOUT")
          commit("IS_ADMIN", false)
          localStorage.removeItem("token")
          localStorage.removeItem("vuex")
          localStorage.removeItem("loglevel:webpack-dev-server")
          axios
            .get("api/v1/logout")
            .then(({data}) => {
              resolve(data)
            })
            .catch(error => {
              reject(error)
            });
          delete axios.defaults.headers.common.Authorization
        });
      }
    }
  },
  getters: {
    loggedIn (state) {
      return state.token !== null
    },
    getCredentialLogin (state) {
      return state.credentials
    },
    getIsAdmin (state) {
      return state.isAdmin
    },
    getIsModerator (state) {
      return state.isModerator
    },
    getIsProfessional (state) {
      return state.isProfessional
    },
  }
}