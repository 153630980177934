<template lang="pug">
#content-sidebar-right
  b-button(variant="outline-mhmotors" v-b-toggle.sidebar-right)
    font-awesome-icon(icon="user-shield")
    span.d-none.d-md-inline.pl-1 Mi Menu
  b-sidebar#sidebar-right(
    aria-labelledby="sidebar-no-header-title",
    no-header,
    right,
    shadow,
    backdrop,
  )
    template(#default="{ hide }")
      .px-3.py-2
        .sidebar-header
          .sidebar-header-close
            b-button-close(@click="hide")
          .sidebar-header-title
            router-link(to="/profile" size="sm" variant="light" v-if="user.active === true")
              b-avatar(v-if="!user.avatar" :text="getName" size="2rem")
              b-avatar(v-else, :src="getImage", size="2rem", :title="getName")
              span.px-2.text-uppercase.fs-6.username {{user.name}}
              font-awesome-icon(icon="pen").text-secondary
        nav.mb-3.menu-user
          b-nav(vertical)
            router-link(:to="{ name:'new-products' }" exact @click.native="isActive = false").menu-user--link
              font-awesome-icon(icon="plus-circle")
              span Nuevo Vehículos
            router-link(:to="{ name:'dashboard' }" exact @click.native="isActive = false").menu-user--link
              font-awesome-icon(icon="list")
              span Mis Vehículos
            router-link(v-if="isAdmin" :to="{ name:'users' }" @click.native="isActive = false").menu-user--link
              font-awesome-icon(icon="users")
              span Usuarios
            router-link(v-if="isAdmin" :to="{ name:'credits-all' }" @click.native="isActive = false").menu-user--link
              font-awesome-icon(icon="coins")
              span Creditos
            router-link(v-if="isAdmin" :to="{ name:'sell-admin' }" @click.native="isActive = false").menu-user--link
              font-awesome-icon(icon="folder-open")
              span Solicitudes
        b-button(variant="warning" block @click="closeSession") Cerrar Sesión
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: "SidebarRight",
  computed: {
    ...mapState('auth', ['credentials', 'isAdmin']),
    getName() {
      const userName = this.user.name.replace(/\./g, '').trim();
      let fullname = userName.split(' ');
      return (fullname[1]) ? fullname[0].charAt(0) + fullname[1].charAt(0) : userName.substring(0, 2);
    },
    getImage() {
      return (this.user.avatar.length > 30) ? this.user.avatar : `${this.$axios.defaults.baseURL}/images/profile/${this.user.avatar}`;
    },
  },
  created() {
    this.user = this.credentials;
  },
  methods: {
    ...mapActions('auth', ['logout']),
    closeSession() {
      this.logout()
        .then(() => {
          if (this.$route.path !== '/') this.$router.push('/');
          this.$bvToast.toast('Cierre de sesión', {
            title: 'Sessión cerrada con éxito',
            variant: 'info',
            autoHideDelay: 3000,
            toaster: 'b-toaster-bottom-right',
            solid: true,
          });
        })
        .catch((err) => {
          throw new Error(`Problem handling something: ${err}.`);
        });
    },
  }
}
</script>
<style lang="scss">
@import '@/assets/scss/_variables';
.sidebar-header {
  align-items: center;
  display: grid;
  grid-template-columns: 30px 1fr;
  margin-bottom: 1rem;
  &-close {
    text-align:left;
    button {
      font-size: 2.5rem;
      float: none;
    }
  }
  &-title {
    text-align: right;
    h3 {
      margin:0;
    }
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .username {
    color: #6c757d;
    &:hover {
      text-decoration: none;
      color: $primary;
    }
  }
}
.menu-user {
    @media (min-width: 577px) {
      top: 80px;
    }
    &--wrapper {
      display: grid;
    }
    &--link {
      border-bottom: solid 1px #eee;
      padding: 8px 0;
      color: #555555;
      span {
        padding-left: .3rem;
      }
      &:hover,
      &:focus {
        color: $primary;
        text-decoration:none;
      }
      &:first-child {
        border-top: solid 1px #eee;
      }
    }
    &.active {
      transform: translateX(0);
    }
  }
</style>