import Vue from "vue"
import Vuex from "vuex"
import axios from "axios"
import VueAxios from 'vue-axios'
import createPersistedState from "vuex-persistedstate"
import { ToastPlugin } from 'bootstrap-vue'

import auth from "@/store/modules/auth"
import image from "@/store/modules/image"
import filter from "@/store/modules/filter"
import region from "@/store/modules/region"
import commune from "@/store/modules/commune"
import bodywork from "@/store/modules/bodywork"
import brand from "@/store/modules/brand"
import model from "@/store/modules/model"
import accessory from "@/store/modules/accessory"

Vue.use(ToastPlugin)
Vue.use(Vuex)
Vue.use(VueAxios, axios)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    loading: false,
  },
  getters: {},
  mutations: {
    SHOW_LOADING(state){
      state.loading = true
    },
    HIDE_LOADING(state){
      state.loading = false
    }
  },
  actions: {},
  modules: {
    auth,
    image,
    filter,
    region,
    commune,
    bodywork,
    brand,
    model,
    accessory
  }
});
