import Vue from "vue"
import axios from "axios"
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

export default {
  namespaced: true,
  state: {
    brands: [],
  },
  mutations:{
    SET_BRANDS(state, payload){
      state.brands = payload
      state.brands.unshift({ id: null, name: "Seleccionar..." })
    },
  },
  actions: {
    getBrands({ commit }) {
      axios
        .get('/api/v1/brands')
        .then(({ data:{data:brands} })=> commit('SET_BRANDS', brands))
        .catch(error => {
          this._vm.$bvToast.toast("Problemas al cargar las carrocerias :(", {
            title: "Error de carga desde API",
            variant: "danger",
            solid: true
          });
          console.error(error)
        })
    },
  },
  getters: {
    getBrandById: (state) => (id) => {
      return state.brands.find(todo => todo.id === id)
    },
  }
}