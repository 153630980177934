import Vue from "vue"
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowAltCircleUp,
  faBan,
  faBars,
  faBullhorn,
  faCalendarAlt,
  faCar,
  faCarSide,
  faCoins,
  faCreditCard,
  faEdit,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFolderOpen,
  faList,
  faMapMarkerAlt,
  faPen,
  faPhoneAlt,
  faPhone,
  faPlus,
  faPlusCircle,
  faRoad,
  faSave,
  faSearch,
  faSignInAlt,
  faSignOutAlt,
  faTag,
  faTags,
  faTimes,
  faTrashAlt,
  faUser,
  faUserShield,
  faUserPlus,
  faUsers,
  faWallet,
} from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faArrowAltCircleUp,
  faBan,
  faBars,
  faBullhorn,
  faCalendarAlt,
  faCar,
  faCarSide,
  faCoins,
  faCreditCard,
  faEdit,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFacebook,
  faFolderOpen,
  faInstagram,
  faList,
  faMapMarkerAlt,
  faPen,
  faPhone,
  faPhoneAlt,
  faPlus,
  faPlusCircle,
  faRoad,
  faSave,
  faSearch,
  faSignInAlt,
  faSignOutAlt,
  faTag,
  faTags,
  faTimes,
  faTrashAlt,
  faTwitter,
  faUser,
  faUserShield,
  faUserPlus,
  faUsers,
  faWallet,
  faWhatsapp,
)

Vue.component('font-awesome-icon', FontAwesomeIcon)