import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "inicio",
    /*jshint -W024 */
    component: () => import('@/views/Index')
  },
  {
    path: "/sell",
    name: "sell",
    component: () => import('@/views/Sell')
  },
  {
    path: "/sell/admin",
    name: "sell-admin",
    component: () => import('@/views/SellAdmin'),
    meta: {
      requiresAuth: true
    }
  },
  /* {
    path: "/register",
    name: "register",
    component: () => import('@/components/login/RegisterComponents')
  }, */
  {
    path: "/login",
    name: "login",
    component: () => import('@/components/login/LoginComponents')
  },
  {
    path: "/reset-password/:token",
    name: "reset-password-form",
    component: () => import('@/components/login/ResetPasswordComponents')
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import('@/components/dashboard/Index'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import('@/components/dashboard/Profile'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/my-shop",
    name: "my-shop",
    component: () => import('@/components/myShop/MyShop')
  },
  {
    path: "/new-products",
    name: "new-products",
    component: () => import('@/components/product/Index'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/detailed-product/:slug/:hashid",
    name: "detailed-product",
    component: () => import('@/components/product/DetailedVehicle')
  },
  {
    path: "/filters",
    name: "filters",
    component: () => import('@/components/formFilterCatalog/NavBarFilterComponents'),
  },
  {
    path: "/vehicles",
    name: "vehicles",
    component: () => import('@/components/formFilterCatalog/MainComponents')
  },
  {
    path: "/thanks/:token",
    name: "thanks",
    component: () => import('@/components/myShop/Thanks')
  },
  {
    path: "/credits/:slug/:hashid",
    name: "credits",
    // component: () => import('@/components/credit/MainCreditComponents')
    component: () => import('@/components/credit/SimpleCreditComponents')
  },
  {
    path: "/credits",
    name: "credits-all",
    component: () => import('@/components/credit/IndexCreditComponents'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/users",
    name: "users",
    component: () => import('@/components/users/Users'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/brands",
    name: "brands",
    component: () => import('@/views/brand/index'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/404",
    name: "not-found",
    component: () => import('@/views/NotFound'),
  },
  {
    path: "*",
    component: () => import('@/views/NotFound')
  }
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  duplicateNavigationPolicy: 'ignore'
});

export default router;
