import Vue from "vue"
import axios from "axios"
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

export default {
  namespaced: true,
  state: {
    keywords: "",
    placeholder: "",
    products: [],
    pagination_complete: true,
    infiniteId: +new Date(),
    filters: {
      page: 1,
      category_id: 1,
      category_classification_id: 1,
      bodywork: "",
      brand: "",
      model: "",
      region: "",
      commune: "",
      traction: "",
      transmission: "",
      fuel:"",
      seats:"",
      timePublished: "",
      prices: [],
      years: [],
      keywords: "",
    },
  },
  mutations: {
    SET_FILTERS: function (state, payload) {
      state.filters.category_id = 1
      state.filters.category_classification_id = 1
      state.filters.bodywork = payload.bodywork ? payload.bodywork.id : 0
      state.filters.brand = payload.brand ? payload.brand.id : 0
      state.filters.model = payload.model ? payload.model.id : 0
      state.filters.region = payload.region ? payload.region.id : 0
      state.filters.commune = payload.commune ? payload.commune.id : 0
      state.filters.traction = payload.traction ? payload.traction.value : 0
      state.filters.transmission = payload.transmission ? payload.transmission.value : 0
      state.filters.fuel = payload.fuel ? payload.fuel.value : 0
      state.filters.seats = payload.seats
      state.filters.timePublished = payload.timePublished
      state.filters.prices = payload.prices
      state.filters.years = payload.years
    },
    SET_REGION: function(state, payload) {
      state.filters.region = payload
    },
    SER_COMMUNE: function(state, payload) {
      state.filters.commune = payload
    },
    SET_BODYWORK: function(state, payload) {
      state.filters.bodywork = payload
    },
    SET_BRAND: function(state, payload) {
      state.filters.brand = payload
    },
    SET_MODEL: function(state, payload) {
      state.filters.model = payload
    },
    SET_YEARS: function(state, payload) {
      state.filters.years = JSON.stringify(payload)
    },
    SET_PRICES: function(state, payload) {
      state.filters.prices = JSON.stringify(payload)
    },
    SET_TRANSMISSION: function(state, payload) {
      state.filters.transmission = payload
    },
    SET_TRACTION: function(state, payload) {
      state.filters.traction = payload
    },
    SET_FUEL: function(state, payload) {
      state.filters.fuel = payload
    },
    SET_SEAT: function(state, payload) {
      state.filters.seats = payload
    },
    SET_PAGE(state, page){
      state.filters.page = page
    },
    SET_KEYWORDS(state, keywords){
      state.filters.keywords = keywords
    },
    SET_PRODUCTS(state, payload){
      state.products.push(...payload )
    },
    CLEAR_PRODUCTS(state){
      state.products = []
    },
    CHANGE_TYPE(state){
      state.infiniteId += 1
    },
    REMOVE_DUPLICATES(state) {
      state.products = state.products.filter((obj, index, self) =>
        index === self.findIndex((el) => el.id === obj.id)
      );
    }
  },
  actions: {
    getProducts({ commit, state }, $state) {
      axios
        .get('/api/v1/product/filter',{ params: state.filters })
        .then(({data:{data:products}}) => {
          if(products.length){
            commit('SET_PAGE', state.filters.page+1)
            commit('SET_PRODUCTS', products)
            commit('REMOVE_DUPLICATES')
            $state.loaded();
          }else{
            $state.complete();
          }
        })
        .catch(error => {
          $state.complete();
          this._vm.$bvToast.toast("Problemas al cargar los vehiculos :(", {
            title: "Error de carga desde API",
            variant: "danger",
            solid: true
          });
          console.error(error)
        })
    }
  },
  getters: {
  }
}