import Vue from "vue"
import axios from "axios"
import VueAxios from "vue-axios"

Vue.use(VueAxios, axios);

if (process.env.NODE_ENV === "development") {
  Vue.axios.defaults.baseURL = process.env.VUE_APP_API_DEVELOMENT;
}
else {
  Vue.axios.defaults.baseURL = process.env.VUE_APP_API_PRODUCTION;
}

Vue.prototype.$axios = axios;

const token = localStorage.getItem("token");
if (token) {
  Vue.axios.defaults.headers.common.Authorization = `Bearer ${token}`
}
