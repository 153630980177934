import Vue from "vue"
import axios from "axios"
import VueAxios from "vue-axios"

Vue.use(VueAxios, axios)

export default {
  namespaced: true,
  state: {
    regions:[]
  },
  mutations:{
    SET_REGIONS(state, payload){
      state.regions = payload
    }
  },
  actions: {
    getRegions({ commit }) {
      axios
        .get("/api/v1/regions")
        .then(({data:{ data:regions }}) => {
          commit("SET_REGIONS", regions)
        })
        .catch(error => {
          this._vm.$bvToast.toast("Problemas al cargar las regiones :(", {
            title: "Error de carga desde API",
            variant: "danger",
            solid: true
          });
          console.error(error)
        })
    }
  },
  getters: {
    getRegionById: (state) => (id) => {
      return state.regions.find(todo => todo.id === id)
    }
  }
}