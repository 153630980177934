export default {
  namespaced: true,
  state: {
    images_store:[],
    maxImages: 10
  },
  mutations: {
    SET_MAXIMUM_IMAGES: function(state) {
      if(this.state.auth.isAdmin){
        state.maxImages = 20
      }
      else if (this.state.auth.isModerator) {
        state.maxImages = 15
      }
      else if (this.state.auth.isProfessional) {
        state.maxImages = 10
      }
      else {
        state.maxImages = 5
      }
    },
    SET_IMAGES(state, payload){
      state.images_store = payload
    },
    CLEAN_IMAGES(state){
      state.images_store = []
    },
  },
  actions: {
    getMaxImages({ commit }) {
      commit("SET_MAXIMUM_IMAGES")
    }
  },
  getters: {}
}