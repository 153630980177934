<template lang="pug">
div#container-navbar
  nav#navBar
    b-container
      b-row.align-items-center
        b-col
          #logo
            router-link.navbar-brand(to="/", :title="$appName")
              img(src="@/assets/img/mhmotors.svg", :alt="$appName")
        b-col.d-none.d-flex-md
          b-nav
            b-nav-item(to="/", active-class="link-active") Inicio
            b-nav-item Nosotros
            b-nav-item Servicios
            b-nav-item Contacto
        b-col#search-container
          #search-top
            b-nav-form(v-on:submit.prevent="onSearch")
              b-input-group.mb-md-0.mb-sm-12
                b-form-input(type="search", v-model="inputSearch", :placeholder="randomSearch()")
                b-input-group-append
                  b-button(type="submit", variant="outline-mhmotors")
                    font-awesome-icon(icon="search")
        sidebar-right(v-if="user.active === true")
        router-link(:to="{ name:'new-products' }" exact @click.native="isActive = false" ).btn.btn-mhmotors.ml-1
          font-awesome-icon(icon="plus-circle")
          span.pl-1.d-none.d-md-inline Publicar Vehículo
        #menuBodyworks.menuBodyworks(:class="{ active: isActiveBodyworks }")
          .menuBodyworks__item(v-for="bodywork in bodyworks", :key="bodywork.id")
            router-link(:to="{ name: 'filters', query: { bodywork: bodywork.slug }}" exact v-on:click.native="hideMenuBodyworks").menuBodyworks__item-link
              i(:class="[bodywork.icon, 'fa-2x']")
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import SidebarRight from './SidebarRight'
export default {
  name: 'Navbar',
  components: {
    SidebarRight
  },
  data() {
    return {
      user: {},
      name: null,
      inputSearch: null,
      isActive: false,
      isActiveBodyworks: false,
    };
  },
  created() {
    this.user = this.credentials;
    this.inputSearch = this.filters.keywords;
    this.getBrands();
  },
  watch: {
    inputSearch: function (input) {
      if(this.$route.fullPath !== '/') {
        if(input.length === 0) this.onSearch();
        if(input.length >= 3) this.onSearch();
      }
      if(this.$route.fullPath === '/'){
        this.SET_KEYWORDS('');
        this.inputSearch = '';
      }
    },
  },
  computed: {
    ...mapState('auth', ['credentials', 'isAdmin']),
    ...mapState('bodywork', ['bodyworks']),
    ...mapState('filter', ['filters']),
    ...mapState('brand', ['brands']),
    ...mapGetters('bodywork', ['getCurrentBodywork', 'getBodyworkById']),
  },
  methods: {
    ...mapMutations('filter', [
      'SET_KEYWORDS',
      'CLEAR_PRODUCTS',
      'CHANGE_TYPE',
      'SET_PAGE',
    ]),
    ...mapMutations(['SHOW_LOADING', 'HIDE_LOADING']),
    ...mapActions('auth', ['logout']),
    ...mapActions('brand', ['getBrands']),
    closeSession() {
      this.logout()
        .then(() => {
          if (this.$route.path !== '/') this.$router.push('/');
          this.$bvToast.toast('Cierre de sesión', {
            title: 'Sessión cerrada con éxito',
            variant: 'info',
            autoHideDelay: 3000,
            toaster: 'b-toaster-bottom-right',
            solid: true,
          });
        })
        .catch((err) => {
          throw new Error(`Problem handling something: ${err}.`);
        });
    },
    hideMenuBodyworks() {
      document.getElementById('menuBodyworks').classList.remove('active');
    },
    async onSearch() {
      try {
        // ya no aplica
        //this.$root.$emit('bv::toggle::collapse', 'search-top');

        const bodywork = await this.getBodyworkById(this.filters.bodywork);
        if ( this.$route.fullPath !== `/filters?bodywork=${bodywork.slug}` ) {
          this.$router.push(`/filters?bodywork=${bodywork.slug}`);
        }
        this.SET_KEYWORDS(this.inputSearch);
        this.SET_PAGE(1);
        this.SHOW_LOADING();
        this.CLEAR_PRODUCTS();
        this.CHANGE_TYPE();
      } catch (errors) {
        console.log(errors);
      } finally {
        this.HIDE_LOADING();
      }
    },
    randomSearch: function () {
      return (
        'Buscar Ej: ' +
        this.brands[Math.floor(Math.random() * this.brands.length)]?.name
          .toLowerCase()
          .trim()
          .split(' ')
          .map((v) => v[0].toUpperCase() + v.substr(1))
          .join(' ')
      );
    },
  },
};
</script>
<style lang="scss">
@import '@/assets/scss/_variables';
#navBar {
  align-items: center;
  background-color: #fff;
  border-bottom: 3px solid $primary;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  left: 0;
  padding: 0 1rem;
  position: fixed;
  right: 0;
  top: 0;
  top: 0;
  z-index: 16;
  .nav {
    @media (max-width: 576px) {
      flex-direction: column;
    }
  }
  .nav-item {
    .nav-link {
      color: rgba(0, 0, 0, 1);
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      &:after {
        content: '';
        background: transparent;
        left: 5px;
        right: 5px;
        top: 100%;
        bottom: 100%;
        outline: 0px solid rgba(0, 0, 0, 0.6);
        transition: all ease 0.3s;
      }
      &:hover {
        color: white;
        &::after {
          background-color: black;
          position: absolute;
          top: 5px;
          bottom: 5px;
          border-radius: 20px;
          z-index: -1;
          outline: 4px solid rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
  #logo {
    order: 1;
    @media (max-width: 576px) {
      text-align: left;
    }
  }
  #search-container {
    display: none;
    @media (min-width: 1200px) {
      display: block;
    }
  }
  #search-top {
    order: 3;
    width: 100%;
    .form-inline {
      margin: 5px auto;
    }
    @media (min-width: 1200px) {
      order: 2;
      display: block;
      width: auto;
    }
  }
  .menuBodyworks {
    align-items: center;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(4, 84px);
    justify-content: center;
    max-height: 242px;
    order: 3;
    text-align: center;
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: all ease 0.3s;
    @media (min-width: 720px) {
      grid-template-columns: repeat(6, 84px);
    }
    &__item {
      &-link {
        text-decoration: none;
        justify-content: center;
        padding: 5px;
        color: #555555;
        &:hover {
          color: $primary;
        }
        &-name {
          font-size: 0.75rem;
        }
      }
      .router-link-active {
        color: $primary;
      }
    }
    &.active {
      height: auto;
      padding: 20px 5px 10px;
    }
  }
  #menu {
    order: 2;
    #btn-search {
      @media (min-width: 851px) {
        display: none;
      }
    }
  }
  #group-button-mobile {
    display: grid;
    grid-template-columns: 1fr 45px;
    gap: 5px;
    align-items: center;
    @media (max-width: 850px) {
      grid-template-columns: 45px 1fr 45px;
      justify-items: center;
    }
    @media (max-width: 460px) {
      grid-template-columns: repeat(3, 1fr);
    }
    .btn-navbar-mobile {
      padding: 0;
      white-space: nowrap;
      text-decoration: none;
    }
    button.btn-navbar-mobile {
      width: 2rem;
      height: 2rem;
    }
    a.btn-navbar-mobile {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .username {
      @media (max-width: 460px) {
        display: none;
      }
    }
  }
  .navbar-brand {
    img {
      width: 65px;
      @media (max-width: 576px) {
        width: 50px;
      }
    }
  }
  .navbar-nav .dropdown-menu {
    border-radius: 20px;
    .b-dropdown-text {
      padding: 1rem 0.8rem;
    }
  }
  .bodywork-menu {
    text-align: center;
    &-name {
      font-size: 0.8rem;
    }
  }
  #menu-category {
    .b-dropdown-text {
      a {
        color: #555555;
        &:hover {
          text-decoration: none;
          color: $primary;
          text-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
  a.dropdown-item {
    text-align: center;
    padding: 0;
    color: #555555;
    text-decoration: none !important;
    font-weight: 600;
    transition: all 0.3s ease;
    display: block;
    margin-bottom: 5px;
    &:hover {
      color: $primary;
      background-color: transparent;
    }
    /* &.active,
			&:active{
				color: $primary !important;
				background-color: transparent;
			} */
  }
  #navbarNav {
    display: block;
  }
  #search-mobile {
    background-color: #fff;
    left: 0;
    padding-bottom: 5px;
    position: fixed;
    right: 0;
    top: 72px;
    z-index: 1031;
    .form-inline {
      justify-content: center;
    }
  }
  .rotate-icon {
    transition: all 0.3s ease;
    animation: spin 0.25s linear;
  }
}
@media (max-width: 576px) {
  input[type='search'].form-control {
    width: 280px !important;
  }
  .btn-sm-block {
    display: block !important;
    width: 100% !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem;
  }
  .mb-sm-3 {
    margin-bottom: 1rem;
  }
  .text-sm-center {
    text-align: center !important;
  }
  .bodywork-name {
    font-size: 0.8rem;
    text-align: center;
  }
}
@keyframes spin {
  100% {
    transform: rotate(90deg);
  }
}
</style>
