import Vue from "vue"
import axios from "axios"
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

export default {
  namespaced: true,
  state: {
    communes: [],
  },
  mutations:{
    SET_COMMUNES(state, payload){
      state.communes = payload
    },
  },
  actions: {
    getCommunes({ commit }, regionID){
      axios
        .get(`/api/v1/communes/${regionID}`)
        .then(({ data:{data:communes} }) =>{
          if(communes) commit("SET_COMMUNES", communes)
          else commit("SET_COMMUNES", [])
        })
        .catch(error => {
          this._vm.$bvToast.toast("Problemas al cargar las comunas :(", {
            title: "Error de carga desde API",
            variant: "danger",
            solid: true
          });
          console.error(error)
        })
    },
    cleanCommunes({commit}) {
      commit("SET_COMMUNES", [])
    }
  },
  getters: {
    getCommuneById: (state) => (id) => {
      if(state.communes){
        return state.communes.find(todo => todo.id === id)
      }else{
        return ''
      }
    },
    existsCommune: (state) => (id) => {
      return (state.communes.find(todo => todo.id === id) !== undefined)
    }
  }
}