import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
//import VueAnalytics from "vue-analytics";
import store from "@/store";

// ServiceWorker
// import "./registerServiceWorker.js";

// Plugins
import "@/plugins/vue-meta";
import "@/plugins/bootstrap-vue";
import "@/plugins/vue-axios";
import "@/plugins/vue-meta";
import "@/plugins/vee-validate";
import "@/plugins/vue-sweetalert2";
import "@/plugins/vuelidate";
import "@/plugins/fontawesome";
import "@/plugins/vue-moment";
import "@/plugins/social-sharing";
import "@/plugins/v-money";
// import "./plugins/vue-zendesk"

// Import css global
import "@/assets/scss/app.scss";

// Filters
import "./filters";

Vue.prototype.$appName = "MHMotors";
Vue.config.productionTip = false;

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters["auth/loggedIn"]) {
      next({
        name: "login",
      });
    } else {
      next();
    }
  } else {
    // make sure to always call next()!
    next();
  }
});

/*Vue.use(VueAnalytics, {
  id: "UA-171578931-1",
  router,
});*/

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
