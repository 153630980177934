import Vue from "vue"
import axios from "axios"
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

export default {
  namespaced: true,
  state: {
    models: [],
  },
  mutations:{
    SET_MODELS(state, payload){
      state.models = payload
      state.models.unshift({ id: 0, name: "Seleccionar..." })
    },
  },
  actions: {
    getModels({ commit }, brandID){
      if(brandID && brandID > 0) {
        axios
        .get(`/api/v1/models/${brandID}`)
        .then(({ data:{ data:models } }) =>{
          commit("SET_MODELS", models)
        })
        .catch(error => {
          this._vm.$bvToast.toast("Problemas al cargar los modelos :(", {
            title: "Error de carga desde API",
            variant: "danger",
            solid: true
          });
          console.error(error)
        })
      }else{
        commit("SET_MODELS", [])
      }
    },
    cleanModels({commit}) {
      commit("SET_MODELS", [])
    }
  },
  getters: {
    getModelById: (state) => (id) => {
      return state.models.find(todo => todo.id === id)
    },
  }
}